import React from "react"
import { Link, graphql } from "gatsby"
import get from "lodash/get"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FormattedMessage } from "gatsby-plugin-react-intl"

import Seo from "../../components/seo"
import Layout from "../../components/layout"
import * as styles from "./job.scss"
import JobPostFormComponent from "./job-post-form/job-post-form"

class JobTemplate extends React.Component {
  render() {
    const job = get(this.props, "data.contentfulJobBlog");

    const options = {
      renderNode: {
        
        [BLOCKS.UL_LIST]: (node, children) => {
          return <ul>{children}</ul>;
        },
        [BLOCKS.LIST_ITEM]: (node, children) => {
          return <li style={{ listStyleType: 'disc' }}>{children}</li>;
        },
        [BLOCKS.EMBEDDED_ASSET]: node => {
          const assetId = node.data.target.sys.id
          const asset = job.body.references?.find(
            reference => reference.contentful_id === assetId
          )

          if (!asset || asset.__typename !== "ContentfulAsset") {
            return null
          }

          const { gatsbyImageData, description } = asset
          return (
            <div className="job-post-image">
              <GatsbyImage
                image={getImage(gatsbyImageData)}
                alt={description || ""}
              />
            </div>
          )
        },
      },
    }

    return (
      <Layout location={this.props.location}>
        <Seo
          title={job.title}
          // image={`https:${job.image.resize.src}`}
          description={job.introText}
        />
        <div className="job-post">

          <div className="job-body">
            {job.body?.raw &&
              renderRichText(job.body, options, {
                references: job.body.references,
              })}
          </div>

          <div className="job-form">
          <h2>Apply now</h2>
          <JobPostFormComponent jobTitle={job.title}></JobPostFormComponent>
          </div>
        </div> 
      </Layout>
    )
  }
}

export default JobTemplate

export const pageQuery = graphql`
  query JobsBlogBySlug($slug: String!, $locale: String) {
    contentfulJobBlog(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      slug
      body {
        raw
      }
      title
      image {
        file {
          url
        }
        title
      }
    }
  }
`
