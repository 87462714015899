import React, { useState } from "react";
import "./job-post-form.scss";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { FormattedMessage, injectIntl } from "gatsby-plugin-react-intl";
import axios from "axios";

import "./job-post-form.scss";

const URL = "https://dpqixvd4ig.execute-api.eu-central-1.amazonaws.com/prod"

const JobPostFormComponent = (props) => {
  const { jobTitle } = props;
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  const [fileTypeError, setFileTypeError] = useState(false);
  const [selectedFile, setSelectedFile] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = async (formValue) => {
    if (!selectedFile) {
      return;
    }

    formValue["type"] = "contact";
    formValue["fileUpload"] = selectedFile;
    formValue["role"] = jobTitle;

    setDisableSubmitBtn(true);

    try {
      const response = await axios.post(URL, formValue, {
        headers: { "Content-Type": "application/json" },
      });

      const uploadUrl = JSON.parse(response.data.body).uploadURL;
      await uploadFile(uploadUrl);

      setDisableSubmitBtn(false);
      reset();
      onSubmitSuccess();
    } catch (error) {
      console.error("Error submitting form:", error);
      setDisableSubmitBtn(false);
    }
  };

  const uploadFile = async (url) => {
    const file = selectedFile;
    try {
      await axios.put(url, file);
      console.log("File uploaded successfully");
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };

  const onSubmitSuccess = () => {
    setShowSuccessMsg(true);
    setTimeout(() => {
      setShowSuccessMsg(false);
    }, 3000);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType === "application/pdf") {
        setSelectedFile(file);
      } else {
        // File is not a PDF or DOCX
        setFileTypeError(true);
      }
    }
  };


  
  


  return (
    <div className="job-form">
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        {/* Full Name field */}
        <div className="form-control">
          <label htmlFor="fullName">
            <FormattedMessage id="contactForm.fullName.label" />
          </label>
          <input
            {...register("fullName", { required: true })}
            id="fullName"
            name="fullName"
            type="text"
          />
          <ErrorMessage
            errors={errors}
            name="fullName"
            render={() => (
              <span>
                <FormattedMessage id="contactForm.fullName.error" />
              </span>
            )}
          />
        </div>

        {/* Email field */}
        <div className="form-control">
          <label htmlFor="email">
            <FormattedMessage id="contactForm.email.label" />
          </label>
          <input
            {...register("email", { required: true })}
            name="email"
            type="email"
            id="email"
          />
          <ErrorMessage
            errors={errors}
            name="email"
            render={() => (
              <span>
                <FormattedMessage id="contactForm.email.error" />
              </span>
            )}
          />
        </div>

        {/* Location field */}
        <div className="form-control">
          <label htmlFor="location">* Location</label>
          <input {...register("location", { required: true })} id="location" name="location" type="text" />
          <ErrorMessage
            errors={errors}
            name="location"
            render={() => (
              <span>
                Location is required
              </span>
            )}
          />
        </div>

        {/* Salary field */}
          <div className="form-control">
          <label htmlFor="salary">* Salary expectations</label>
          <input {...register("salary", { required: true })} id="salary" name="salary" type="text" />
          <ErrorMessage
            errors={errors}
            name="location"
            render={() => (
              <span>
                Salary expectations is required
              </span>
            )}
          />
        </div>

        {/* Portfolio field */}
        <div className="form-control">
          <label htmlFor="portfolio">Portfolio URL</label>
          <input {...register("portfolio")} id="portfolio" name="portfolio" type="text" />
        </div>

        {/* Free text area */}
        <div className="form-control text-area">
          <label htmlFor="message">Tell us about you</label>
          <textarea
            {...register("message")}
            name="message"
            className="text-area"
            id="message"
          ></textarea>
        </div>

       {/* File upload button */}
       <div className="form-control upload-field">
          <label htmlFor="fileUpload">Upload your CV (PDF)</label>
          <div className="file-upload-wrapper">
            <input
              {...register("fileUpload", { required: true })}
              id="file"
              name="file"
              type="file"
              accept=".pdf,.docx"
              onChange={handleFileUpload}
            />
          </div>{fileTypeError && <small style={{color: 'red'}}>Supported files types only .PDF</small>}
          <ErrorMessage
            errors={errors}
            name="message"
            render={() => (
              <span>
                File is required
              </span>
            )}
          />
        </div>

        <div className="submit-wrapper">
          <div className="send-message">
            {showSuccessMsg && (
              <>
                Application was sent successfully
                <span>
                  <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z" />
                  </svg>
                </span>
              </>
            )}
          </div>

          <div className="send-button">
            <button disabled={disableSubmitBtn} className="button-main">
              <FormattedMessage id="contactForm.submitBtn" />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default JobPostFormComponent;
